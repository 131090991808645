import type { BuildingResourceDTO } from './building-resource-dto';

export interface DiscussionsFormInitialValuesDTO {
  ownerName: string;
  ownerImage?: string;
  ownerInitials: string;
  poll: boolean;
  text: string;
  published: boolean;
  publishedAt: string;
  updatedAt: string;
  imageUrl?: string;
  imagePublicId?: string;
  endDate?: string;
  options?: string[];
  buildings?: BuildingResourceDTO[];
  audience: DiscussionAudienceSelection;
}

export interface DiscussionsFormPreviewValuesDTO {
  text: string;
  timeSince: string;
  ownerDisplayName: string;
  ownerImage?: string;
  ownerInitials: string;
  imagePublicId?: string;
}

export enum DiscussionAudienceSelection {
  ALL_BUILDINGS = 'All',
  BUILDINGS = 'BUILDINGS',
}

export const DiscussionFormTabs = {
  Form: 'form',
  Preview: 'preview',
} as const;

export const DiscussionFormAction = {
  Save: 'save',
  Preview: 'preview',
} as const;
